@import "../../../variables.scss";
@import "../../../mixins.scss";

.tabs-component {
  .tabs-button-list {
    display: flex;
    $gutter-width: 10px;
    margin: 0;

    @include devices(tablet) {
      margin: 0 -30px;
      flex-direction: column;
      align-items: center;
    }

    @include devices(mobile) {
    }

    .tabs-button {
      display: block;
      width: 33.3%;
      border: 1px solid rgba(black, 0.25);
      background: rgba(#dedbbb, 0.15);
      outline: none;
      margin: 0 $gutter-width;
      padding: 10px 0;
      font-size: 20px;
      font-weight: 400;
      border-radius: 4px;
      margin-bottom: 15px;

      @include devices(tablet) {
        font-size: 18px;
      }

      &.active {
        background: #dedbbb;
        border: 1px solid rgba(black, 0.25);
      }
    }
  }
  .tabs-content-list {
    .tabs-content {
      display: none;
      padding: 70px 0;

      @include devices(tablet) {
        padding: 40px 0;
      }

      &.active {
        display: block;
      }
    }
  }
}

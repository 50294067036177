@import "../../variables.scss";


.icons-list {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 0 auto;
}

.icons-item {
    margin: 20px;
}

.icon {
    height: 40px;
    width: 40px;
    transition: all 300ms linear;

    &:hover, &:focus {
        transform: scale(1.2);       
    }
}

.telegram {
    &:hover, &:focus {
        fill: #229ED9;
    }
}

.signal {
    &:hover, &:focus {
        fill: #2196F3;
    }
}

.whatsapp {
    &:hover, &:focus {
        fill: #25D366;
    }
}

.fake-link {
    position: relative;
}

.copy-number {
        position: absolute;
        // transform: translate(0%, 0%);
        width: 100px;
        font-size: 14px;
        font-weight: 500;
        display: block;
        transition: opacity 1s;
        cursor: none;
        pointer-events: none;
        opacity: 0;
        text-align: left;
        color: $color-text-link;
  
        &.active {
          opacity: 1;
          transition: opacity 0.1s;
        }
}

$color-nav-text: #000000;
$text-muted: #ced4da;
$text-grey-muted: #c4c4c4;
$text-grey: #878787;
$color-success: #099361;
$color-danger: #e86051;
$color-text-value: #383838;
$color-border-grey: #dee2e6;
$color-text-link: #0066dd;

$grid-gutter: 35px;

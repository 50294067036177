@import "../../variables.scss";

.app-component {
  .container-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sign-out-btn {
    cursor: pointer;
  }
  .site-nav {
    display: flex;
    height: 76px;
    margin-bottom: 20px;

    a {
      display: inline-block;
      color: $color-nav-text;
      text-decoration: none;
    }

    .nav-menu {
      display: flex;
      align-items: center;

      .nav-logo {
        margin: 0 10px 0 0;

        img {
          width: 40px;
        }
      }

      a {
        margin: 0 9px;

        &.active {
          font-weight: 700;
        }
      }
    }
  }
}

@import "../../../variables.scss";

.grid-col-component {
  box-sizing: border-box;

  &.bg-false {
    background-color: none;
  }

  .block-title {
    margin: 0 0 10px;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    color: $color-success;
  }
  .block-content {
  }

  &.cols-1 {
    width: (1 / 12) * 100%;
  }
  &.cols-2 {
    width: (2 / 12) * 100%;
  }
  &.cols-3 {
    width: (3 / 12) * 100%;
  }
  &.cols-4 {
    width: (4 / 12) * 100%;
  }
  &.cols-5 {
    width: (5 / 12) * 100%;
  }
  &.cols-6 {
    width: (6 / 12) * 100%;
  }
  &.cols-7 {
    width: (7 / 12) * 100%;
  }
  &.cols-8 {
    width: (8 / 12) * 100%;
  }
  &.cols-9 {
    width: (9 / 12) * 100%;
  }
  &.cols-10 {
    width: (10 / 12) * 100%;
  }
  &.cols-11 {
    width: (11 / 12) * 100%;
  }
  &.cols-12 {
    width: (12 / 12) * 100%;
  }
}

@mixin devices($breakpoint) {
  //the name of the mixin is devices

  @if $breakpoint == tablet {
    @media only screen and (max-width: 1024px) {
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (max-width: 481px) {
      @content;
    }
  }
}

@import "../../../variables.scss";

.grid-container-component {
  display: flex;
  margin-left: -$grid-gutter / 2;
  margin-right: -$grid-gutter / 2;

  > .grid-col-component {
    margin-left: $grid-gutter / 2;
    margin-right: $grid-gutter / 2;
  }
}

@import "../../../variables.scss";

.copy-text-component {
  cursor: pointer;

  .copy-icon {
    width: 19px;
    margin-left: 10px;
    transform: translateY(-1px);
  }
  .copy-text-copy {
    position: relative;
    font-size: 22px;

    .copy-text-copied {
      position: absolute;
      top: 0;
      left: 100%;
      // width: 100px;
      font-size: 12px;
      font-weight: 500;
      display: block;
      transform: translate(5px, 4px);
      transition: opacity 1s;
      opacity: 0;
      text-align: left;
      color: $color-text-link;

      &.active {
        opacity: 1;
        transition: opacity 0.1s;
      }
    }
    .copy-icon-check {
      position: absolute;
      top: 10px;
      left: 15px;
      width: 12px;
      opacity: 0;
      transition: opacity 1s;

      &.active {
        opacity: 1;
        transition: opacity 0.1s;
      }
    }
  }
}
